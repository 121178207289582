<template>
  <div id="c-body">
    <CBanner :bannerCont="bannerCont"/>
    <div id="content">
      <p style="
        text-align: center;    
        font-size: 22px;    
        line-height: 50px;"
      >
        联系我们
      </p>
      <p>邮箱：<a href="mailto:Official@necphc.com">Official@necphc.com</a></p>
      <p>地址：<a href="http://www.necphc.com">http://www.necphc.com</a></p>
      <p>Q Q：1709134171</p>
      <p>电话：029-81148661</p>
    </div>
    <div class="footer">
      <Footer/>
    </div>
  </div>
</template>

<script>
import CBanner from '../common/content/CBanner.vue'
import Footer from '../common/Footer'
export default {
  name: "abouts",
  components: {
    'CBanner': CBanner,
    'Footer': Footer
  },
  data() {
    return {
      bannerCont: {
        pic: require('../../../assets/show-banner.png'),
        title: "公司简介",
        txt: "面向复杂的实际工程问题提供解决方案, 做到精心服务全方位掌控, 为攻克卡脖子难题做出自己的贡献坚持以需求为导向，以量身定制技术为核心，以自主攻关为使命",
      },
    }
  }
}
</script>

<style scoped>
div#c-body {
  max-width: 1920px;
  margin: 0 auto;
}
div#content {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 100px;
  color: #323232;
  text-indent: 2em;
  font-size: 14px;
  line-height: 240%;
  text-align: justify;
  text-justify: distribute;
}
div.footer {
  position: relative;
  top: 100px;
}
</style>
